import React from 'react'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import VideoPlayer from 'components/video-player'
import Wrapper from 'components/wrapper'

export default function VideoPage({ location, params }) {
  const { key } = params
  return (
    <Wrapper
      location={location}
      variant="video"
      author=""
      bodyClass="bg-body-darker"
      wrapperClass="d-flex flex-column w-100 mx-auto"
    >
      <Meta />
      <Row className="py-3 text-center">
        <Col>
          <VideoPlayer mediaKey={key} showTitle showNotice />
        </Col>
      </Row>
    </Wrapper>
  )
}
